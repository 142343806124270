@if (serviceDetails.serviceName == 'Nurse/Caregiver') {
<div
  (click)="redirect()"
  class="hover:border-secondary-dark bg-gradient-to-tr from-secondary/20 to-white duration-300 group md:my-3 hover:bg-blue-100 cursor-pointer w-full h-[130px] lg:h-[150px] flex flex-col gap-2 md:gap-3 justify-center items-center p-2 md:p-3 border-[1px] rounded-[10px]"
>
  <img
    class="md:max-w-[50px] max-w-[50px]"
    width="50"
    height="50"
    [ngSrc]="
      serviceDetails.imgUrl
        ? serviceDetails.imgUrl
        : '/assets/banner/diagn.webp'
    "
    alt="service"
  />
  <h2
    class="font-Roboto text-[14px] md:text-[18px] block text-center text-primary overflow-hidden line-clamp-2"
  >
    <span>{{ serviceDetails.serviceName || "Service" }}</span>
  </h2>
  <svg
    width="64px"
    height="30px"
    class="group-hover:block hidden duration-300"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M4 12H20M20 12L16 8M20 12L16 16"
        stroke="#01204e"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </g>
  </svg>
</div>

}@else {
<a
  [href]="serviceDetails.link"
  class="hover:border-secondary-dark bg-gradient-to-tr from-secondary/20 to-white duration-300 group md:my-3 hover:bg-blue-100 cursor-pointer w-full h-[130px] lg:h-[150px] flex flex-col gap-2 md:gap-3 justify-center items-center p-2 md:p-3 border-[1px] rounded-[10px]"
>
  <img
    class="md:max-w-[50px] max-w-[50px]"
    width="50"
    height="50"
    [ngSrc]="
      serviceDetails.imgUrl
        ? serviceDetails.imgUrl
        : '/assets/banner/diagn.webp'
    "
    alt="service"
  />
  <h2
    class="font-Roboto text-[14px] md:text-[18px] block text-center text-primary overflow-hidden line-clamp-2"
  >
    <span>{{ serviceDetails.serviceName || "Service" }}</span>
  </h2>
  <svg
    width="64px"
    height="30px"
    class="group-hover:block hidden duration-300"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M4 12H20M20 12L16 8M20 12L16 16"
        stroke="#01204e"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </g>
  </svg>
</a>
}

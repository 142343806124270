import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import {
  DoctorScheduleService,
  FinancialSetupService,
} from 'src/app/proxy/services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TosterService } from 'src/app/shared/services/toster.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { LiveConsultBookingDialogComponent } from '../live-consult-booking-dialog/live-consult-booking-dialog.component';

@Component({
  selector: 'app-live-doctor-card',
  templateUrl: './live-doctor-card.component.html',
  styleUrls: ['./live-doctor-card.component.scss'],
})
export class LiveDoctorCardComponent {
  isAuthUser: number;
  isLoading: boolean = false;
  userType: string = '';
  picUrls: any;
  Math = Math;
  @Input() doctorDetails: any;
  subs = new SubSink();
  //displayDoctorTitle: any[] = Common.displayDoctorTitle.filter(i => i.id > 0);
  defaultImage = 'assets//assets/doctor/avater.png';
  public picUrl = `${environment.apis.default.url}/`;

  constructor(
    private DoctorScheduleService: DoctorScheduleService,
    private NormalAuth: AuthService,
    public dialog: MatDialog,
    private TosterService: TosterService,
    private FinancialSetupService: FinancialSetupService,
    private router: Router
  ) {
    this.isAuthUser = this.NormalAuth.authInfo()?.id;
    this.userType = this.NormalAuth.authInfo()?.userType;

    //this.picUrls = this.picUrl + this.doctorDetails.profilePic;
  }

  onClickConsultNow(data: any) {
    this.openDialog(data);
  }
  goToProfile(id: number) {
    this.router.navigate([`/search/doctors/${id}`]);
  }
  openDialog(data: any): void {
    this.isLoading = true;

    if (data.id) {
      let a: any;

      const detailsSchedule$ =
        this.DoctorScheduleService.getDetailsScheduleListByDoctorId(data.id);
      const financialSetup$ =
        this.FinancialSetupService.getListByProviderIdandType(
          1,
          data.id,
          this.userType
        );

      forkJoin([detailsSchedule$, financialSetup$]).subscribe({
        next: ([detailsScheduleRes, financialSetupRes]) => {
          if (detailsScheduleRes?.length > 0 && data) {
            this.isLoading = false;
            this.dialog.open(LiveConsultBookingDialogComponent, {
              maxWidth: 600,
              minWidth: 320,
              disableClose: true,
              data: {
                doctorDetails: data,
                doctorScheduleInfo: detailsScheduleRes,
                isAuthUser: this.isAuthUser ? true : false,
                userAccess: this.userType == 'doctor' ? false : true,
                isAgentSpecific: false,
                serviceFeeList: financialSetupRes, // Add the serviceFeeList to the data object
              },
            });
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.TosterService.customToast(`Something went wrong`, 'error');
        },
      });
    } else {
      this.isLoading = false;
      this.TosterService.customToast(`No Details/Schedule found`, 'warning');
    }
  }

  //displayDoctorTtileName(titleId:any) {
  //  return this.displayDoctorTitle.find(s => s.id == titleId).name;
  //}
}
